import React from 'react';
import CustomizedMenusContainer from '../containers/CustomizedMenusContainer';
import CellPhonesRegisterContainer from '../containers/CellPhonesRegisterContainer';
import WorkerTypeSpanish from '../data/worker_type_spanish.json';
import constants from '../data/constants.json';
import {
  UserFrontend,
  WorkUserFrontend,
} from '../services/BackendFrontendInterfaces';
import TreeViewContainer from '../containers/TreeViewContainer';

const SexType = constants.SexSpanishEnum;
const selectormenu = constants.keyOrValue;
const showTokenInput = process.env.REACT_APP_FIRSTINVITEDUSERSTOKEN;

interface RegistrationUserFormProps {
  id?: string;
  formValues: UserFrontend;
  showPassword: boolean;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChangeSexType: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleCheckboxWorkerTypeChange: (value: WorkUserFrontend['workType']) => void;
  handleClickShowPassword: () => void;
  handleMouseDownPassword: (event: React.MouseEvent) => void;
  handleSubmit: (event: React.FormEvent) => void;
  handleCellPhonesRegistrados: (cellPhonesList: number[]) => void;
}

const RegistrationUserFormPresentation: React.FC<RegistrationUserFormProps> = ({
  id = 'RegistrationUser',
  formValues,
  showPassword,
  handleInputChange,
  handleSelectChangeSexType,
  handleCheckboxWorkerTypeChange,
  handleClickShowPassword,
  handleMouseDownPassword,
  handleSubmit,
  handleCellPhonesRegistrados,
}) => {
  return (
    <div>
      <form className="App" onSubmit={handleSubmit} id={`Form${id}`}>
        <div>
          <label htmlFor={`input${id}id`} id={`label${id}id`}>
            INE:
          </label>
          <input
            id={`input${id}id`}
            name="id"
            type="file"
            accept=".jpg"
            required
            onChange={handleInputChange}
          />
          {!showTokenInput && (
            <>
              <label htmlFor={`input${id}token`} id={`label${id}token`}>
                token:
              </label>
              <input
                id={`input${id}token`}
                name="token"
                type="text"
                required
                value={formValues.token}
                onChange={handleInputChange}
              />
            </>
          )}
        </div>
        <br />
        <div>
          <label htmlFor={`input${id}CURP`} id={`label${id}CURP`}>
            CURP:
          </label>
          <input
            id={`input${id}CURP`}
            name="curp"
            type="text"
            required
            value={formValues.curp}
            onChange={handleInputChange}
          />
          <label htmlFor={`input${id}Email`} id={`label${id}Email`}>
            Email:
          </label>
          <input
            id={`input${id}Email`}
            name="email"
            type="email"
            required
            value={formValues.email}
            onChange={handleInputChange}
          />
        </div>
        <br />
        <div>
          <label htmlFor={`input${id}Password`} id={`label${id}Password`}>
            Contraseña:
          </label>
          <input
            id={`input${id}Password`}
            name="password"
            type={showPassword ? 'text' : 'password'}
            required
            value={formValues.password}
            onChange={handleInputChange}
          />
          <label
            htmlFor={`input${id}PasswordConfirm`}
            id={`label${id}PasswordConfirm`}
          >
            Confirmar Contraseña:
          </label>
          <input
            id={`input${id}PasswordConfirm`}
            name="confirm_password"
            type={showPassword ? 'text' : 'password'}
            required
            value={formValues.confirm_password}
            onChange={handleInputChange}
          />
          <button
            id={`button${id}ShowPassword`}
            type="button"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? 'Ocultar Contraseña' : 'Mostrar Contraseña'}
          </button>
        </div>
        <br />
        <div>
          <label htmlFor={`input${id}FirstName`} id={`label${id}FirstName`}>
            Primer Nombre:
          </label>
          <input
            id={`input${id}FirstName`}
            name="firstName"
            type="text"
            required
            value={formValues.firstName}
            onChange={handleInputChange}
          />
          <label htmlFor={`input${id}SecondName`} id={`label${id}SecondName`}>
            Segundo Nombre:
          </label>
          <input
            id={`input${id}SecondName`}
            name="secondName"
            type="text"
            value={formValues.secondName}
            onChange={handleInputChange}
          />
        </div>
        <br />
        <div>
          <label
            htmlFor={`input${id}FirstLastname`}
            id={`label${id}FirstLastname`}
          >
            Primer Apellido:
          </label>
          <input
            id={`input${id}FirstLastname`}
            name="firstLastName"
            type="text"
            required
            value={formValues.firstLastName}
            onChange={handleInputChange}
          />
          <label
            htmlFor={`input${id}SecondLastname`}
            id={`label${id}SecondLastname`}
          >
            Segundo Apellido:
          </label>
          <input
            id={`input${id}SecondLastname`}
            name="secondLastName"
            type="text"
            required
            value={formValues.secondLastName}
            onChange={handleInputChange}
          />
        </div>
        <br />
        <div>
          <p>Sexo</p>
          <CustomizedMenusContainer
            id={`CustomizedMenusRegisterUser`}
            keyOrValue={selectormenu.Key}
            options={SexType}
            onItemClick={handleSelectChangeSexType}
          />
          <p>Tipo De Usuario: {`${formValues.workerType}`}</p>
          <TreeViewContainer
            readOnly={false}
            id={`treeViewRegisterUser`}
            categories={{
              'Click Aquí Para Seleccionar Tipo De Trabajador':
                WorkerTypeSpanish.WorkerTypeSpanish,
            }}
            treeHandler={handleCheckboxWorkerTypeChange}
          />
        </div>
        <br />
        <div>
          <label htmlFor={`input${id}RFC`} id={`label${id}RFC`}>
            RFC:
          </label>
          <input
            id={`input${id}RFC`}
            name="rfc"
            type="text"
            required
            value={formValues.rfc}
            onChange={handleInputChange}
          />
          <label
            htmlFor={`input${id}ProfessionalCedule`}
            id={`label${id}ProfessionalCedule`}
          >
            Número De Cédula Profesional:
          </label>
          <input
            id={`input${id}ProfessionalCedule`}
            name="linkCedule"
            type="text"
            value={formValues.linkCedule}
            onChange={handleInputChange}
          />
        </div>
        <br />
        <div>
          <button id={`button${id}Register`} type="submit">
            Registrarse
          </button>
        </div>
        <br />
        <div>
          <CellPhonesRegisterContainer
            id={`cellPhonesRegisterRegisterUser`}
            onRegisteredCellPhones={handleCellPhonesRegistrados}
          />
        </div>
      </form>
    </div>
  );
};

export default RegistrationUserFormPresentation;
