import React, { useEffect, useState, useContext } from 'react';
import constants from '../data/constants.json';
import EditUserFormPresentation from '../components/EditUserFormPresentation';
import { makeRequest } from '../services/requests';
import {
  UserBackend,
  UserBackendToSend,
  UserFrontend,
} from '../services/BackendFrontendInterfaces';
import { SessionContext } from '../services/SessionContextProvider';

const SexType = constants.SexSpanishEnum;

const EditUserFormContainer: React.FC = () => {
  const [userData, setUserData] = useState<UserBackend | null>(null);
  const [cellPhones, setCellPhones] = useState<number[]>([]);
  const [readOnly, setReadOnly] = useState(true);
  const [selectedOptionsWorkerType, setSelectedOptionsWorkerType] = useState<
    string[]
  >([]);
  const [showPassword, setShowPassword] = useState(false);

  const { meBackend, setMeBackend } = useContext(SessionContext);

  const [formValues, setFormValues] = useState<UserFrontend>({
    curp: '',
    info: false,
    email: '',
    sex: SexType.Hombre,
    rfc: '',
    linkCedule: '',
    identityValidated: false,
    rate_approved: 0,
    approved_by: 0,
    rate_disapproved: 0,
    disapproved_by: 0,
    rate_works_evaluated: 0,
    works_evaluated: 0,
    password: '',
    confirm_password: '',
    workerType: [],
    firstName: '',
    secondName: '',
    firstLastName: '',
    secondLastName: '',
    cellPhones: [],
    id: null,
  });

  const fetchUserData = async () => {
    try {
      const path = `user/get_me`;
      const method = 'GET';
      let data = meBackend;
      if (meBackend == null) {
        const response = await makeRequest({ path: path, method: method });
        data = await response.json();
        setMeBackend(data);
      }
      setUserData(data);
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message);
      } else if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        'componentStack' in error
      ) {
        const reactError = error as React.ErrorInfo;
        alert(reactError);
      } else {
        alert(JSON.stringify(error));
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (userData != null) {
      setFormValues((prevValues) => ({
        ...prevValues,
        curp: userData.curp,
        info: userData.info,
        email: userData.email,
        sex: userData.sex,
        rfc: userData.rfc,
        linkCedule: userData.link_cedule,
        identityValidated: userData.identity_validated,
        rate_approved: userData.rate_approved,
        approved_by: userData.approved_by,
        rate_disapproved: userData.rate_disapproved,
        disapproved_by: userData.disapproved_by,
        rate_works_evaluated: userData.rate_works_evaluated,
        works_evaluated: userData.works_evaluated,
        password: userData.password,
        firstName: userData.first_name.name,
        secondName: userData.second_name.name,
        firstLastName: userData.first_lastname.lastname,
        secondLastName: userData.second_lastname.lastname,
      }));
      const getPhoneNumbers = (person: UserBackend) =>
        person.cell_phones.map((phone) => phone.cell_phone);
      setCellPhones(getPhoneNumbers(userData));
      const getWorkerType = (person: UserBackend) =>
        person.worker_type.map((worker_type) => worker_type.type);
      setSelectedOptionsWorkerType(getWorkerType(userData));
    }
  }, [userData]);

  const validatePasswords = () => {
    if (formValues.password !== formValues.confirm_password) {
      alert('Las contraseñas no coinciden');
      return false;
    }
    return true;
  };

  const handleCellPhonesRegistrados = (cellPhones: number[]) => {
    setCellPhones(cellPhones);
  };

  useEffect(() => {
    setFormValues((prevValues) => ({ ...prevValues, cellPhones: cellPhones }));
  }, [cellPhones]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent) =>
    event.preventDefault();

  const handleCheckboxWorkerTypeChange = (value: {
    name: string | null;
    value: string[];
  }) => {
    setSelectedOptionsWorkerType(value.value);
  };

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      workerType: selectedOptionsWorkerType,
    }));
  }, [selectedOptionsWorkerType]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { name, value } = event.target;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    if (name.includes('id')) {
      if (event.target.files != null) {
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: event.target.files![0],
        }));
      }
    }
  };

  const handleSelectChangeSexType = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedValue = event.target.value as keyof typeof SexType;

    setFormValues((prevValues) => ({
      ...prevValues,
      sex: Object.keys(SexType).includes(selectedValue)
        ? SexType[selectedValue]
        : SexType.Hombre,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (readOnly) {
      setReadOnly(false);
    } else {
      if (!validatePasswords()) {
        return;
      }

      try {
        const obj_to_send: UserBackendToSend = {
          email: formValues.email,
          sex: formValues.sex,
          link_cedule: formValues.linkCedule,
          worker_type: formValues.workerType.map((item) => ({
            type: item,
          })),
          first_name: { name: formValues.firstName },
          second_name: { name: formValues.secondName },
          first_lastname: { lastname: formValues.firstLastName },
          second_lastname: { lastname: formValues.secondLastName },
          cell_phones: formValues.cellPhones.map((item) => ({
            cell_phone: item,
            user_id: userData?.id!,
          })),
          curp: formValues.curp,
          rfc: formValues.rfc,
          password: formValues.password,
        };
        const path = `user/update_me`;
        const method = 'PATCH';

        const content_type = 'application/json';
        setMeBackend(null);
        const response = await makeRequest({
          path: path,
          method: method,
          body: JSON.stringify(obj_to_send),
          contentType: content_type,
        });

        if (!response.ok) {
          const response_json = await response.json();
          throw new Error(JSON.stringify(response_json.detail));
        }
        if (formValues.id) {
          const header = {
            Accept: 'application/json',
          };
          const formData = new FormData();
          formData.append('id', formValues.id);
          const response = await makeRequest({
            path: 'user/my_id',
            method: 'POST',
            body: formData,
            header: header,
          });
        }
        // Mostrar una notificación de éxito
        alert('Cambio exitoso');
        // Recargar el componente
        window.location.reload();
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message);
        } else if (
          typeof error === 'object' &&
          error !== null &&
          'message' in error &&
          'componentStack' in error
        ) {
          const reactError = error as React.ErrorInfo;
          alert(reactError);
        } else {
          alert(JSON.stringify(error));
        }
      }
    }
  };

  return (
    <EditUserFormPresentation
      userData={userData}
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
      handleMouseDownPassword={handleMouseDownPassword}
      handleClickShowPassword={handleClickShowPassword}
      handleSelectChangeSexType={handleSelectChangeSexType}
      handleCheckboxWorkerTypeChange={handleCheckboxWorkerTypeChange}
      handleCellPhonesRegistrados={handleCellPhonesRegistrados}
      readOnly={readOnly}
      formValues={formValues}
      showPassword={showPassword}
      cellPhones={cellPhones}
    />
  );
};

export default EditUserFormContainer;
